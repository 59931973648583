import * as AdjustmentCreateBladeModule from "../blades/adjustment-create-blade/adjustment-create-blade.module";
import * as AdjustmentUpdateBladeModule from "../blades/adjustment-update-blade/adjustment-update-blade.module";

export default class AdjustmentsBladeNavigationService implements Adjustments.Navigation.IAdjustmentsBladeNavigationService {
    private _coreNavigationService: any;
    constructor(coreNavigationService: any) {
        this._coreNavigationService = coreNavigationService;
    }

    public showCreateBlade(parentBlade: any, adjustmentLevel: AdjustmentLevel): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.adjustments.adjustment-create-blade.id",
            title: "Create Adjustment",
            subtitle: "Adjustment creation form",
            controller: AdjustmentCreateBladeModule.controllerName,
            template: AdjustmentCreateBladeModule.templateUrl,
            toolbarCommands: [],

            data:
            {
                taxLevel: adjustmentLevel
            }
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }

    public showUpdateBlade(parentBlade: any, adjustment: Adjustments.Dto.IAdjustmentDto): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.adjustments.adjustment-update-blade.id",
            title: "Update Adjustment",
            subtitle: "Adjustment update form",
            controller: AdjustmentUpdateBladeModule.controllerName,
            template: AdjustmentUpdateBladeModule.templateUrl,
            toolbarCommands: [],

            data:
            {
                tax: adjustment
            }
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }
}
