import Angular from "../globals/angular.global";

import * as adjustmentLevelsBladeModule from "../adjustment-levels-blade/adjustment-levels-blade.module";
import * as platform from "../globals/platform/platform";
import * as itemServicesModule from "../item/services/item-services.module";

import AdjustmentsMainBladeController from "./main-blade.controller";

// constants
export const name = "tcs.adjustments.main-blade";
export const controllerName = "tcs.adjustments.main-blade.ctrl";
export const templateUrl = "tcs.adjustments.main-blade.html";

// module
Angular
    .module(name,
    [
        itemServicesModule.name,
        platform.moduleName,
        adjustmentLevelsBladeModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        itemServicesModule.navigationServiceName,
        platform.bladesServiceName,
        AdjustmentsMainBladeController
    ])
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let bladeTemplate = require("./main-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
