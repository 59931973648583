﻿import Angular from "./globals/angular.global";

import * as platform from "./globals/platform/platform";
import * as mainBladeModule from "./main-blade/main-blade.module";

export const name = "tcs.adjustments";

Angular
    .module(name,
    [
        "tcs.module.myarline.manager",
        mainBladeModule.name
    ])
    .run([
        'tcs.module.myarline.manager.tcs-menu',
        (tcsMenuSrv: any)=> {
            tcsMenuSrv.addMenuItem('vendor-pricing', {
                path: 'vendor-pricing/adjustments',
                icon: 'fa fa-usd',
                title: 'pricing-adjustments.menu',
                priority: 50,
                bladeId: 'adjustmentsBlade',
                bladeTitle: 'pricing-adjustments.menu',
                bladeController: mainBladeModule.controllerName,
                bladeTemplate: mainBladeModule.templateUrl,
                addVendorName: true
            });
        }
    ]);

let appDependencies = (<any>window).AppDependencies;
if (appDependencies) {
    appDependencies.push(name);
}
