// dependencies
import Angular from "../../../globals/angular.global";

import * as expressionsServicesModule from "../../expressions/services/expressions.services.module";

import * as adjustmentCreateModule from "../../components/adjustment-create/adjustment-create.module";

import AdjustmentCreateBladeController from "./adjustment-create-blade.controller";

// constants
export const name = "tcs.adjustments.adjustment-create-blade";
export const controllerName = "tcs.adjustments.adjustment-create-blade.ctrl";
export const templateUrl = "tcs.adjustments.adjustment-create-blade.template.html";

// module
Angular
    .module(name,
    [
        adjustmentCreateModule.name,
        expressionsServicesModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        expressionsServicesModule.uiServiceName,
        AdjustmentCreateBladeController
    ])
    .run(["$templateCache",($templateCache: angular.ITemplateCacheService) =>
    {
        let bladeTemplate = require("./adjustment-create-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
