// dependencies
import Angular from "../../../globals/angular.global";

import * as currenciesServicesModule from "../../../currencies/services/currencies.services.module";
import * as storesServicesModule from "../../../stores/services/stores.services.module";
import * as expressionsServicesModule from "../../expressions/services/expressions.services.module";
import * as adjustmentsServicesModule from "../../services/adjustments.services.module";

import AdjustmentCreateController from "./adjustment-create.controller";

// constants
export const name = "tcs.adjustments.adjustment-create";
const controllerName = "tcs.adjustments.adjustment-create.ctrl";
const selectorName = "tcsAdjustmentsCreate";

// module
Angular
    .module(name,
        [
            storesServicesModule.name,
            adjustmentsServicesModule.name,
            expressionsServicesModule.name,
            expressionsServicesModule.name,
            currenciesServicesModule.name
        ])
    .controller(controllerName,
        [
            storesServicesModule.apiServiceName,
            adjustmentsServicesModule.apiServiceName,
            expressionsServicesModule.apiServiceName,
            expressionsServicesModule.uiServiceName,
            currenciesServicesModule.apiServiceName,
            AdjustmentCreateController
        ])
    .directive(selectorName, () => {
        let directive: angular.IDirective =
            {
                restrict: "E",
                template: require("./adjustment-create.html"),
                scope:
                    {
                        onCreateComplete: "&",
                        onLoadStarted: "&",
                        onLoadCompleted: "&",
                        onSelectCountryClicked: "&",
                        onSelectClassClicked: "&",
                        onSelectStationClicked: "&",
                        onSelectProductClicked: "&",
                        toolbar: "=",
                        taxLevel: "="
                    },
                controller: controllerName,
                controllerAs: "vm",
                bindToController: true
            };
        return directive;
    });
