export default class AdjustmentCreateBladeController
{
    constructor(public $scope: any, private expressionsUiService: Adjustments.Expressions.Services.IExpressionsUiService)
    {
        $scope.createCompleteHandler = () =>
        {
            // Method defined at TravellerTaxesController
            $scope.blade.parentBlade.onTaxListChanged();
            $scope.bladeClose();
        };
        $scope.showLoadbar = () =>
        {
            $scope.blade.isLoading = true;
        };
        $scope.hideLoadbar = () =>
        {
            $scope.blade.isLoading = false;
        };
        $scope.showSelectCountryBlade = (parentElement: any) => {
            expressionsUiService.openCountrySelectBlade(parentElement, $scope.blade);
        };
        $scope.showSelectStationBlade = (parentElement: any) => {
            expressionsUiService.openStationSelectBlade(parentElement, $scope.blade);
        };
        $scope.showSelectClassBlade = (parentElement: any) => {
            expressionsUiService.openClassSelectBlade(parentElement, $scope.blade);
        };
        $scope.showSelectProductBlade = (parentElement: any) => {
            expressionsUiService.openProductSelectBlade(parentElement, $scope.blade);
        };
    }
}
