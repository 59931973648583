import * as itemAdjustmentsBladeModule from "../item-adjustments-blade/item-adjustments-blade.module";
import { IItemBladeNavigationService } from "./item-services.interfaces";

export default class ItemBladeNavigationService implements IItemBladeNavigationService {
    private _coreNavigationService: any;
    constructor(coreNavigationService: any) {
        this._coreNavigationService = coreNavigationService;
    }

    public showAllAdjustmentsBlade(parentBlade: any): void {
        let blade: Globals.Platform.IBladeConfiguration = {
            id: "tcs.adjustments.item.item-adjustments-blade.id",
            title: "Item Adjustments",
            subtitle: "item adjustments",
            controller: itemAdjustmentsBladeModule.controllerName,
            template: itemAdjustmentsBladeModule.templateUrl,
            vendor: parentBlade.vendor,
            toolbarCommands: [],
            data: null
        };
        this._coreNavigationService.showBlade(blade, parentBlade);
    }
}
