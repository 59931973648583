import Angular from "../globals/angular.global";

import * as platform from "../globals/platform/platform";
import * as itemServicesModule from "../item/services/item-services.module";

import AdjustmentsMainListBladeController from "./adjustment-levels-blade.controller";

// constants
export const name = "tcs.adjustments.adjustment-levels-blade";
export const controllerName = "tcs.adjustments.adjustment-levels-blade.ctrl";
export const templateUrl = "tcs.adjustments.adjustment-levels-blade.html";

// module
Angular
    .module(name,
    [
        itemServicesModule.name,
        platform.moduleName
    ])
    .controller(controllerName,
    [
        "$scope",
        itemServicesModule.navigationServiceName,
        platform.bladesServiceName,
        AdjustmentsMainListBladeController
    ])
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let bladeTemplate = require("./adjustment-levels-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
