// dependencies
import Angular from "../../../globals/angular.global";

import * as expressionsServicesModule from "../../expressions/services/expressions.services.module";

import * as adjustmentUpdateModule from "../../components/adjustment-update/adjustment-update.module";

import AdjustmentUpdateBladeController from "./adjustment-update-blade.controller";

// constants
export const name = "tcs.adjustments.adjustment-update-blade";
export const controllerName = "tcs.adjustments.adjustment-update-blade.ctrl";
export const templateUrl = "tcs.adjustments.adjustment-update-blade.template.html";

// module
Angular
    .module(name,
    [
        adjustmentUpdateModule.name,
        expressionsServicesModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        expressionsServicesModule.uiServiceName,
        AdjustmentUpdateBladeController
    ])
    .run(["$templateCache",($templateCache: angular.ITemplateCacheService) =>
    {
        let bladeTemplate = require("./adjustment-update-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
