import * as templateNames from "./templates/index";

export default class AdjustmentsListController {
    // bindings
    public taxes: Adjustments.Dto.IAdjustmentDto[];
    public onTaxClicked: () => any;
    public onDelete: () => any;

    // view model
    public gridOptions: any;
    public selectedTax: Adjustments.Dto.IAdjustmentDto;

    constructor(uiGridService: any) {
        this.taxes = this.taxes || [];

        this._setGridOptions();
        // this._uiGridService.initialize(this, this.gridOptions);
    }

    public taxClicked(tax: Adjustments.Dto.IAdjustmentDto) {
        let handler = this.onTaxClicked();
        if (typeof handler === "function") {
            handler(tax);
        }
    }
    public delete() {
        let handler = this.onDelete();
        if (typeof handler === "function") {
            handler(this.selectedTax);
        }
    }

    private _setGridOptions() {
        let actionsColumn: any =
            {
                name: "actions",
                displayName: "",
                enableColumnResizing: false,
                enableSorting: false,
                width: 30,
                pinnedLeft: true,
                cellTemplate: templateNames.actionsCellTemplateName,
            };
        let nameColumn: any =
            {
                name: "name",
                displayName: "name",
            };

        this.gridOptions =
            {
                useExternalSorting: true,
                rowTemplate: templateNames.rowTemplateName,
                rowHeight: 59,
                data: "vm.taxes",
                columnDefs:
                [
                    actionsColumn,
                    nameColumn
                ]
            };
    }
}
