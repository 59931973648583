export default class ItemAdjustmentsController {
// bindings
public listChangedEventHandlers: any[];
public onLoadStarted: () => any;
public onLoadCompleted: () => any;
public onCreateClicked: () => any;
public onUpdateClicked: () => any;
public toolbar: any[];

public parentBlade: any;

// view model
public adjustments: Adjustments.Dto.IAdjustmentDto[];
public total: number;
public hasPager: boolean;
public filter: Adjustments.Dto.Queries.IAllAdjustmentsQuery;
public updateClicked: (tax: Adjustments.Dto.IAdjustmentDto) => void;
public deleteClicked: (tax: Adjustments.Dto.IAdjustmentDto) => void;

private _adjustmentsApiService: Adjustments.Services.IAdjustmentsApiService;

constructor
    (
        public $scope: any,
        adjustmentsApiService: Adjustments.Services.IAdjustmentsApiService
    ) {
    this._adjustmentsApiService = adjustmentsApiService;

    this.updateClicked = (adjustment: Adjustments.Dto.IAdjustmentDto): void => {
        let handler = this.onUpdateClicked();
        if (typeof handler === "function") {
            handler(adjustment);
        }
    };
    this.deleteClicked = (adjustment: Adjustments.Dto.IAdjustmentDto): void => {
        this._adjustmentsApiService
            .delete(adjustment)
            .then((result: any) => {
                this._load();
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    this.parentBlade = $scope.$parent.blade;
    this._initializeFilter();
    this._initializeToolbar();
    this._initializeEventHandlers();
    this._load();
}

public keywordChanged(event: KeyboardEvent): void {
    if (event.which !== 13 || !this.filter.keyword) {
        return;
    }
}
public clearKeyword(): void {
    this.filter.keyword = "";
}

private _load(): void {
    this._startLoad();
    this._adjustmentsApiService
        .getAll(this.filter)
        .then((result: Adjustments.Dto.IAdjustmentsDto) => {
            this.adjustments = result.collection;
            this.total = this.adjustments.length;
            this.hasPager = this.filter.itemsPerPage < this.total;

            this._completeLoad();

            return this.adjustments;
        })
        .catch((error: any) => console.error(error));
}
private _initializeFilter(): void {
    this.filter =
        {
            keyword: "",
            itemsPerPage: 20,
            currentPage: 1,
            level: AdjustmentLevel.Item,
            storeId: this.parentBlade.vendor.storeId
        };
}

private _initializeToolbar(): void {
    this.toolbar =
        [
            {
                name: "platform.commands.refresh",
                icon: "fa fa-refresh",
                executeMethod: () => this._load(),
                canExecuteMethod: () => true
            },
            {
                name: "platform.commands.add",
                icon: "fa fa-plus",
                executeMethod: () => this._createClicked(),
                canExecuteMethod: () => true,
                // permission: 'marketing:create'
            }
        ];
}

private _initializeEventHandlers(): void {
    let dataChangedHandler = () => { this._load(); };
    this.listChangedEventHandlers.push(dataChangedHandler);
}

private _startLoad() {
    let handler = this.onLoadStarted();
    if (typeof handler === "function") {
        handler();
    }
}
private _completeLoad() {
    let handler = this.onLoadCompleted();
    if (typeof handler === "function") {
        handler();
    }
}
private _createClicked() {
    let handler = this.onCreateClicked();
    if (typeof handler === "function") {
        handler();
    }
}
}
