export const ageConditionTemplateUrl = "taxes.expressions.ageCondition.html";
export const ageConditionIsChildTemplateUrl = "taxes.expressions.ageConditionIsChild.html";
export const ageConditionIsAdultTemplateUrl = "taxes.expressions.ageConditionIsAdult.html";
export const ageConditionIsInfantTemplateUrl = "taxes.expressions.ageConditionIsInfant.html";

export const countryConditionTemplateUrl = "taxes.expressions.countryCondition.html";
export const countryConditionIsDepartsTemplateUrl = "taxes.expressions.countryConditionIsDeparts.html";
export const countryConditionIsArrivesTemplateUrl = "taxes.expressions.countryConditionIsArrives.html";

export const classConditionTemplateUrl = "taxes.expressions.classCondition.html";
export const classItemTemplateUrl = "taxes.expressions.classItem.html";

export const stationConditionTemplateUrl = "taxes.expressions.stationCondition.html";
export const stationConditionIsDepartsTemplateUrl = "taxes.expressions.stationConditionIsDeparts.html";
export const stationConditionIsArrivesTemplateUrl = "taxes.expressions.stationConditionIsArrives.html";

export const productConditionTemplateUrl = "taxes.expressions.productCondition.html";
export const productItemTemplateUrl = "taxes.expressions.productItem.html";

export const bundleConditionTemplateUrl = "taxes.expressions.bundleCondition.html";
export const bundleItemTemplateUrl = "taxes.expressions.bundleItem.html";

export const bundleRelationConditionTemplateUrl = "taxes.expressions.bundleRelationCondition.html";
export const bundleRelationConditionIsIncludedTemplateUrl = "taxes.expressions.bundleRelationConditionIsIncluded.html";
export const bundleRelationConditionIsOptionalTemplateUrl = "taxes.expressions.bundleRelationConditionIsOptional.html";
