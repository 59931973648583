import * as adjustmentLevelsBladeModule from "../adjustment-levels-blade/adjustment-levels-blade.module";

export default class AdjustmentsMainBladeController {
    private _itemBladeNavigationService: any;
    private _coreNavigationService: any;
    private _currentBlade: any;
    private _parentBlade: any;

    constructor(
        public $scope: any,
        itemBladeNavigationService: any,
        coreNavigationService: any
    ) {
        this._itemBladeNavigationService = itemBladeNavigationService;
        this._coreNavigationService = coreNavigationService;

        this._parentBlade = this.$scope.$parent.blade;
        this._currentBlade = this.$scope.blade;
        this.$scope.links = [];

        $scope.clickItem = (item: any) => {
            if (item.action) {
                item.action();
            }
            for (let i = 0; i < $scope.links.length; i++) {
                this.$scope.links[i].isSelected = false;
            }
            item.isSelected = true;
        };

        this._load();
    }

    private _load(): void {
        let yieldAdjustmentsBlade =
            {
                name: "Yield Adjustments",
                icon: "list-ico fa fa-calendar",
                isSelected: false,
                action: () => {
                    let newBlade = {
                        icon: 'fa fa-calendar',
                        id: 'calendarConfiguratorBlade',
                        title: 'calendar-configuration.widgets.configuration.blade-title',
                        controller: 'tcs.pricingCostModule.mock',
                        template: 'Modules/$(TCS.PricingCost)/Scripts/blades/mock/view.tpl.html',
                        vendor: this._parentBlade.vendor,
                        toolbarCommands: [],
                        data: null
                    };
                    this._coreNavigationService.showBlade(newBlade, this._currentBlade);

                }
            };

        let marketingAdjustmentsBlade =
            {
                name: "Marketing Adjustments",
                icon: "fa fa-list-alt",
                isSelected: false,
                action: () => {
                    let newBlade = {
                        id: "marketingAdjustmentsBlade",
                        title: 'Marketing Adjustments',
                        subtitle: 'Marketing Adjustments',
                        controller: 'tcs.pricingCostModule.mock',
                        template: 'Modules/$(TCS.PricingCost)/Scripts/blades/mock/view.tpl.html',
                        vendor: this._parentBlade.vendor,
                        toolbarCommands: [],
                        data: null
                    };
                    this._coreNavigationService.showBlade(newBlade, this._currentBlade);

                }
            };

        let strategicAdjustmentsBlade =
            {
                name: "Strategic Adjustments",
                icon: "fa fa-list-alt",
                isSelected: false,
                action: () => {
                    let newBlade = {
                        id: "strategicAdjustmentsBlade",
                        title: 'Strategic Adjustments',
                        subtitle: 'Strategic Adjustments',
                        controller: adjustmentLevelsBladeModule.controllerName,
                        template: adjustmentLevelsBladeModule.templateUrl,
                        vendor: this._parentBlade.vendor,
                        toolbarCommands: [],
                        data: null
                    };
                    this._coreNavigationService.showBlade(newBlade, this._currentBlade);

                }
            };

        this.$scope.links.push(yieldAdjustmentsBlade, marketingAdjustmentsBlade, strategicAdjustmentsBlade);

        this._currentBlade.isLoading = false;
    }
}
