import { _ } from "../../../globals/underscore.globals";

export default class AdjustmentExpressionsUiService implements Adjustments.Expressions.Services.IExpressionsUiService {

    private _authService: any;
    private _coreNavigationService: any;
    constructor(authService: any, coreNavigationService: any) {
        this._authService = authService;
        this._coreNavigationService = coreNavigationService;
    }

    public openCountrySelectBlade(parentElement: any, parentBlade: any): void {
        let selectedListEntries: any[] = [];

        let navigation = this._coreNavigationService;

        let countryBlade: Globals.Platform.IBladeConfiguration = {
            id: "countryEntrySelect",
            title: "Select county",
            controller: "virtoCommerce.catalogModule.catalogItemSelectController",
            template: "Modules/$(VirtoCommerce.Catalog)/Scripts/blades/common/catalog-items-select.tpl.html",
            breadcrumbs: [],
            toolbarCommands: [
                {
                    name: "platform.commands.pick-selected", icon: 'fa fa-plus',
                    executeMethod:  (blade: any) => {
                        parentElement.data.CountryCode = selectedListEntries[0].code;
                        parentElement.productName = selectedListEntries[0].name;
                        navigation.closeBlade(blade);
                    },
                    canExecuteMethod: () => {
                        return selectedListEntries.length === 1;
                    }
                }
            ]

        };

        countryBlade.options = {
            showCheckingMultiple: false,
            checkItemFn: (listItem: any, isSelected: any) => {
                if (listItem.type === 'category') {
                    countryBlade.error = 'Must select Product';
                    listItem.selected = undefined;
                } else {
                    if (isSelected) {
                        if (_.all(selectedListEntries, (x: any) => { return x.id !== listItem.id; })) {
                            selectedListEntries.push(listItem);
                        }
                    }
                    else {
                        selectedListEntries = _.reject(selectedListEntries, (x: any) => { return x.id === listItem.id; });
                    }
                    countryBlade.error = undefined;
                }
            }
        };

        this._coreNavigationService.showBlade(countryBlade, parentBlade);
    }

    public openStationSelectBlade(parentElement: any, parentBlade: any): void {
        let selectedListEntries: any[] = [];

        let navigation = this._coreNavigationService;

        let countryBlade: Globals.Platform.IBladeConfiguration = {
            id: "stationEntrySelect",
            title: "Select station",
            controller: "virtoCommerce.catalogModule.catalogItemSelectController",
            template: "Modules/$(VirtoCommerce.Catalog)/Scripts/blades/common/catalog-items-select.tpl.html",
            breadcrumbs: [],
            toolbarCommands: [
                {
                    name: "platform.commands.pick-selected", icon: 'fa fa-plus',
                    executeMethod:  (blade: any) => {
                        parentElement.data.StationCode = selectedListEntries[0].code;
                        parentElement.productName = selectedListEntries[0].name;
                        navigation.closeBlade(blade);
                    },
                    canExecuteMethod: () => {
                        return selectedListEntries.length === 1;
                    }
                }
            ]

        };

        countryBlade.options = {
            showCheckingMultiple: false,
            checkItemFn: (listItem: any, isSelected: any) => {
                if (listItem.type === 'category') {
                    countryBlade.error = 'Must select Product';
                    listItem.selected = undefined;
                } else {
                    if (isSelected) {
                        if (_.all(selectedListEntries, (x: any) => { return x.id !== listItem.id; })) {
                            selectedListEntries.push(listItem);
                        }
                    }
                    else {
                        selectedListEntries = _.reject(selectedListEntries, (x: any) => { return x.id === listItem.id; });
                    }
                    countryBlade.error = undefined;
                }
            }
        };

        this._coreNavigationService.showBlade(countryBlade, parentBlade);
    }

    public openClassSelectBlade(parentElement: any, parentBlade: any): void {
        let selectedListEntries: any[] = [];

        let navigation = this._coreNavigationService;

        let countryBlade: Globals.Platform.IBladeConfiguration = {
            id: "classEntrySelect",
            title: "Select class",
            controller: "virtoCommerce.catalogModule.catalogItemSelectController",
            template: "Modules/$(VirtoCommerce.Catalog)/Scripts/blades/common/catalog-items-select.tpl.html",
            breadcrumbs: [],
            vendor: "",
            toolbarCommands: [
                {
                    name: "platform.commands.pick-selected", icon: 'fa fa-plus',
                    executeMethod: (blade: any) => {
                        parentElement.data.ClassCode = selectedListEntries[0].code;
                        parentElement.productName = selectedListEntries[0].name;
                        navigation.closeBlade(blade);
                    },
                    canExecuteMethod: () => {
                        return selectedListEntries.length === 1;
                    }
                }
            ]

        };

        countryBlade.options = {
            showCheckingMultiple: false,
            checkItemFn: (listItem: any, isSelected: any) => {
                if (listItem.type === 'category') {
                    countryBlade.error = 'Must select Product';
                    listItem.selected = undefined;
                } else {
                    if (isSelected) {
                        if (_.all(selectedListEntries, (x: any) => { return x.id !== listItem.id; })) {
                            selectedListEntries.push(listItem);
                        }
                    }
                    else {
                        selectedListEntries = _.reject(selectedListEntries, (x: any) => { return x.id === listItem.id; });
                    }
                    countryBlade.error = undefined;
                }
            }
        };

        this._coreNavigationService.showBlade(countryBlade, parentBlade);
    }

    public openBundleSelectBlade(parentElement: any, parentBlade: any): void {
        let selectedListEntries: any[] = [];

        let navigation = this._coreNavigationService;

        let countryBlade: Globals.Platform.IBladeConfiguration = {
            id: "bundleEntrySelect",
            title: "Select bundle",
            controller: "virtoCommerce.catalogModule.catalogItemSelectController",
            template: "Modules/$(VirtoCommerce.Catalog)/Scripts/blades/common/catalog-items-select.tpl.html",
            breadcrumbs: [],
            vendor: "",
            toolbarCommands: [
                {
                    name: "platform.commands.pick-selected", icon: 'fa fa-plus',
                    executeMethod: (blade: any) => {
                        parentElement.data.ClassCode = selectedListEntries[0].code;
                        parentElement.productName = selectedListEntries[0].name;
                        navigation.closeBlade(blade);
                    },
                    canExecuteMethod: () => {
                        return selectedListEntries.length === 1;
                    }
                }
            ]

        };

        countryBlade.options = {
            showCheckingMultiple: false,
            checkItemFn: (listItem: any, isSelected: any) => {
                if (listItem.type === 'category') {
                    countryBlade.error = 'Must select Product';
                    listItem.selected = undefined;
                } else {
                    if (isSelected) {
                        if (_.all(selectedListEntries, (x: any) => { return x.id !== listItem.id; })) {
                            selectedListEntries.push(listItem);
                        }
                    }
                    else {
                        selectedListEntries = _.reject(selectedListEntries, (x: any) => { return x.id === listItem.id; });
                    }
                    countryBlade.error = undefined;
                }
            }
        };

        this._coreNavigationService.showBlade(countryBlade, parentBlade);
    }

    public openProductSelectBlade(parentElement: any, parentBlade: any): void {
        let selectedListEntries: any[] = [];

        let navigation = this._coreNavigationService;

        let countryBlade: Globals.Platform.IBladeConfiguration = {
                    id: "productEntrySelect",
                    title: "Select product",
                    controller: "virtoCommerce.catalogModule.catalogItemSelectController",
                    template: "Modules/$(VirtoCommerce.Catalog)/Scripts/blades/common/catalog-items-select.tpl.html",
                    breadcrumbs: [],
                    vendor: "",
                    toolbarCommands: [
                        {
                            name: "platform.commands.pick-selected", icon: 'fa fa-plus',
                            executeMethod: (blade: any) => {
                                parentElement.data.ProductCode = selectedListEntries[0].code;
                                parentElement.productName = selectedListEntries[0].name;
                                navigation.closeBlade(blade);
                            },
                            canExecuteMethod: () => {
                                return selectedListEntries.length === 1;
                            }
                        }
                    ]

                };

        countryBlade.options = {
                    showCheckingMultiple: false,
                    checkItemFn: (listItem: any, isSelected: any) => {
                        if (listItem.type === 'category') {
                            countryBlade.error = 'Must select Product';
                            listItem.selected = undefined;
                        } else {
                            if (isSelected) {
                                if (_.all(selectedListEntries, (x: any) => { return x.id !== listItem.id; })) {
                                    selectedListEntries.push(listItem);
                                }
                            }
                            else {
                                selectedListEntries = _.reject(selectedListEntries, (x: any) => { return x.id === listItem.id; });
                            }
                            countryBlade.error = undefined;
                        }
                    }
                };

        this._coreNavigationService.showBlade(countryBlade, parentBlade);
    }

}
