export default class AdjustmentLevelsBladeController {
    private _itemBladeNavigationService: any;
    private _coreNavigationService: any;
    private _currentBlade: any;
    private _parentBlade: any;

    constructor(
        public $scope: any,
        itemBladeNavigationService: any,
        coreNavigationService: any
    ) {
        this._itemBladeNavigationService = itemBladeNavigationService;
        this._coreNavigationService = coreNavigationService;

        this._parentBlade = this.$scope.$parent.blade;
        this._currentBlade = this.$scope.blade;
        this.$scope.links = [];

        $scope.clickItem = (item: any) => {
            if (item.action) {
                item.action();
            }
            for (let i = 0; i < $scope.links.length; i++) {
                this.$scope.links[i].isSelected = false;
            }
            item.isSelected = true;
        };

        this._load();
    }

    private _load(): void {
        let itemAdjusmentsLink =
            {
                name: "Item adjustments",
                icon: "fa fa-image",
                isSelected: false,
                action: () => {
                    this._itemBladeNavigationService
                        .showAllAdjustmentsBlade(this._currentBlade);
                }
            };

        this.$scope.links.push(itemAdjusmentsLink);

        this._currentBlade.isLoading = false;
    }
}
