// dependencies
import Angular from "../../../globals/angular.global";

import * as currenciesApiModule from "../../../currencies/services/currencies.services.module";
import * as storesServicesModule from "../../../stores/services/stores.services.module";
import * as adjustmentsServicesModule from "../../services/adjustments.services.module";

import AdjustmentUpdateController from "./adjustment-update.controller";

// constants
export const name = "tcs.adjustments.adjustment-update";
const controllerName = "tcs.adjustments.adjustment-update.ctrl";
const selectorName = "tcsAdjustmentsUpdate";

// module
Angular
    .module(name,
    [
        storesServicesModule.name,
        adjustmentsServicesModule.name,
        currenciesApiModule.name
    ])
    .controller(controllerName,
    [
        storesServicesModule.apiServiceName,
        adjustmentsServicesModule.apiServiceName,
        currenciesApiModule.apiServiceName,
        AdjustmentUpdateController
    ])
    .directive(selectorName, () =>
    {
        let directive: angular.IDirective =
            {
                restrict: "E",
                template: require("./adjustment-update.html"),
                scope:
                {
                    onUpdateComplete: "&",
                    onLoadStarted: "&",
                    onLoadCompleted: "&",
                    onSelectCountryClicked: "&",
                    onSelectClassClicked: "&",
                    onSelectProductClicked: "&",
                    onSelectStationClicked: "&",
                    toolbar: "=",
                    tax: "="
                },
                controller: controllerName,
                controllerAs: "vm",
                bindToController: true
            };
        return directive;
    });
