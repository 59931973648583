// dependencies
import Angular from "../../globals/angular.global";

import * as adjustmentsListModule from "../../adjustments-strategic/components/adjustments-list/adjustments-list.module";
import * as adjustmentsServicesModule from "../../adjustments-strategic/services/adjustments.services.module";

import ItemAdjustmentsController from "./item-adjustments.controller";

// constants
export const name = "tcs.adjustments.item";
const controllerName = "tcs.adjustments.item.ctrl";
const selectorName = "tcsItemAdjustments";

// module
Angular
    .module(name,
    [
        adjustmentsServicesModule.name,
        adjustmentsListModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        adjustmentsServicesModule.apiServiceName,
        ItemAdjustmentsController
    ])
    .directive(selectorName, () => {
        let directive: angular.IDirective =
            {
                restrict: "E",
                template: require("./item-adjustments.html"),
                scope:
                {
                    listChangedEventHandlers: "=",
                    toolbar: "=",
                    onLoadStarted: "&",
                    onLoadCompleted: "&",
                    onCreateClicked: "&",
                    onUpdateClicked: "&"
                },
                controller: controllerName,
                controllerAs: "vm",
                bindToController: true
            };
        return directive;
    });
