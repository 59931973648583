// dependencies
import Angular from "../../globals/angular.global";

import AdjustmentsApiService from "./adjustments.api.service";

// constants
export const name = "tcs.adjustments.adjustment-services";
export const apiServiceName = "tcs.adjustments.adjustment-services.api-service";

// module
Angular
    .module(name,
    [
    ])
    .service(apiServiceName,
    [
        "$http",
        AdjustmentsApiService
    ]);
