export default class ItemAdjustmentsBladeController{
    constructor(public $scope: any, adjustmentsNavigationService: Adjustments.Navigation.IAdjustmentsBladeNavigationService){
        $scope.dataChangedEventHandlers = [];
        $scope.blade.onTaxListChanged = () => {
            // execute all subscriptions to data
            $scope.dataChangedEventHandlers.forEach((handler: any) => {
                if (typeof handler === "function") {
                    handler();
                }
            });
        };
        $scope.showTaxCreateBlade = () => {
            adjustmentsNavigationService.showCreateBlade($scope.blade, AdjustmentLevel.Item);
        };
        $scope.showTaxUpdateBlade = (adjustment: Adjustments.Dto.IAdjustmentDto) => {
            adjustmentsNavigationService.showUpdateBlade($scope.blade, adjustment);
        };
        $scope.showLoadbar = () => {
            $scope.blade.isLoading = true;
        };
        $scope.hideLoadbar = () => {
            $scope.blade.isLoading = false;
        };
    }
}
