// dependencies
import Angular from "../../globals/angular.global";

import StoresApiService from "./stores.api.service";

// constants
export const name = "tcs.adjustments.stores.services";
export const apiServiceName = "tcs.adjustments.stores.services.api";

// module
Angular
    .module(name, [])
    .service(apiServiceName,
    [
        "$http",
        StoresApiService
    ]);
