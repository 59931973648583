export default class AdjustmentsApiService implements Adjustments.Services.IAdjustmentsApiService {
    private _http: angular.IHttpService;
    private _resourceUrl: string;

    constructor($http: angular.IHttpService) {
        this._http = $http;
        this._resourceUrl = "api/travel/adjustments/strategic";
    }

    public getAll(filter: Adjustments.Dto.Queries.IAllAdjustmentsQuery): angular.IPromise<Adjustments.Dto.IAdjustmentsDto> {
        return this._http
            .get(this._resourceUrl, { params: filter })
            .then((result: any) => { return result.data; });
    }
    public create(adjustment: Adjustments.Dto.IAdjustmentDto): angular.IPromise<any> {

        let data = { adjustment: adjustment };
        return this._http
            .post(this._resourceUrl, data)
            .then(result => result.data);
    }
    public update(adjustment: Adjustments.Dto.IAdjustmentDto): angular.IPromise<any> {
        let data = { adjustment: adjustment };
        let requestUrl = `${this._resourceUrl}/${adjustment.id}`;
        return this._http
            .put(requestUrl, data);
    }
    public delete(adjustment: Adjustments.Dto.IAdjustmentDto): angular.IPromise<any> {
        let requestUrl = `${this._resourceUrl}/${adjustment.id}`;
        return this._http
            .delete(requestUrl);
    }
}
