// dependencies
import Angular from "../../globals/angular.global";

import * as createAdjustmentBladeModule from "../../adjustments-strategic/blades/adjustment-create-blade/adjustment-create-blade.module";
import * as updateAdjustmentBladeModule from "../../adjustments-strategic/blades/adjustment-update-blade/adjustment-update-blade.module";
import * as adjustmentNavigationModule from "../../adjustments-strategic/navigation/adjustments.navigation.module";
import * as itemAdjustmentsModule from "../item-adjustments/item-adjustments.module";

import ItemAdjustmentsBladeController from "./item-adjustments-blade.controller";

// constants
export const name = "tcs.adjustments.item.item-adjustments-blade";
export const controllerName = "tcs.adjustments.item.item-adjustments-blade.ctrl";
export const templateUrl = "tcs.adjustments.item.item-adjustments-blade.template.html";

// module
Angular
    .module(name,
    [
        itemAdjustmentsModule.name,
        createAdjustmentBladeModule.name,
        updateAdjustmentBladeModule.name,
        adjustmentNavigationModule.name
    ])
    .controller(controllerName,
    [
        "$scope",
        adjustmentNavigationModule.navigationServiceName,
        ItemAdjustmentsBladeController
    ])
    .run(["$templateCache", ($templateCache: angular.ITemplateCacheService) => {
        let bladeTemplate = require("./item-adjustments-blade.html");
        $templateCache.put(templateUrl, bladeTemplate);
    }]);
